export const sudoHeaders = ["Sudo-Fodselsnr", "Sudo-Organisasjonsnr"] as const;

type SudoHeader = typeof sudoHeaders;

export const sudoHeadersByKey = {
  Fnr: "Sudo-Fodselsnr",
  Orgnr: "Sudo-Organisasjonsnr",
} as const;

export type SudoHeaders = Record<SudoHeader[number], string>;
