import { useTranslations } from "next-intl";

import { Heading2 } from "@sikt/sds-core";
import { ArrowSquareOutIcon } from "@sikt/sds-icons";

import ButtonLink from "../button-link/button-link";
import ContentCol from "./content-col";
import css from "./footer.module.css";

const Feedback = () => {
  const t = useTranslations("footer.feedback");

  return (
    <ContentCol className={css.feedback}>
      <Heading2 variant="paragraph" className={css.heading}>
        {t("have-feedback")}
      </Heading2>
      <ButtonLink
        icon={<ArrowSquareOutIcon />}
        target="_blank"
        href="https://nettskjema.no/a/467713#/page/1"
        className={css.button}
      >
        {t("give-feedback")}
      </ButtonLink>
    </ContentCol>
  );
};

export default Feedback;
