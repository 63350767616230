import clsx from "clsx";

import { forwardRef } from "react";

import { useLocale } from "next-intl";

import { ButtonLinkProps as SDSButtonLinkProps } from "@sikt/sds-button";

import {
  ExternalLink,
  Href,
  InternalLink,
  LinkProps,
  isInternalHref,
} from "@/src/intl/link";
import { Locale } from "@/src/intl/locale";
import { extractChildrenTextContent } from "@/src/utils/react-functions/extract-children-text-content";

export type ButtonLinkProps = Omit<SDSButtonLinkProps, "href"> &
  Omit<LinkProps, "href"> & {
    href: Href;
    className?: string;
  };

const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      href,
      variant = "subtle",
      icon,
      iconVariant = "right",
      locale,
      ...props
    }: ButtonLinkProps,
    ref,
  ) => {
    const defaultLocale = useLocale() as Locale;

    const className = clsx(
      "sds-button-link",
      "sds-button",
      `sds-button--${variant}`,
      props.className,
    );

    const content = (
      <>
        {icon && (iconVariant === "left" || iconVariant === "only") && (
          <span className="sds-button__icon">{icon}</span>
        )}
        {(!icon || iconVariant !== "only") && (
          <span className="sds-button__label">{children}</span>
        )}
        {icon && iconVariant === "right" && (
          <span className="sds-button__icon">{icon}</span>
        )}
      </>
    );

    return isInternalHref(href) ? (
      <InternalLink
        {...props}
        href={href}
        locale={locale ?? defaultLocale}
        className={className}
        ref={ref}
        ignoreSdsStyling
        aria-label={
          iconVariant === "only"
            ? extractChildrenTextContent(children)
            : undefined
        }
      >
        {content}
      </InternalLink>
    ) : (
      <ExternalLink
        {...props}
        href={href}
        className={className}
        ref={ref}
        ignoreSdsStyling
      >
        {content}
      </ExternalLink>
    );
  },
);

ButtonLink.displayName = "ButtonLink";

export default ButtonLink;
