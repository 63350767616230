import { gql } from "@/src/__generated__";

export const PersonInfoFragment = gql(/* GraphQL */ `
  fragment PersonInfo on Person {
    id
    identifikasjon {
      type
      identifikator
    }
  }
`);
