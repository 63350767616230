"use client";

import { useLocale } from "next-intl";
import { useParams } from "next/navigation";

import ButtonLink from "@/src/components/button-link/button-link";
import css from "@/src/components/header/menu/language/language.module.css";
import { Locale, getAvailableLocalesToFullName } from "@/src/intl/locale";
import { usePathname } from "@/src/intl/navigation";
import { WithChildren } from "@/src/types/generic-react-props";

interface LanguageLinkProps extends WithChildren {
  locale: Locale;
}

const LanguageLink = ({ locale, children }: LanguageLinkProps) => {
  const pathname = usePathname();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const params = useParams() as never;

  return (
    <ButtonLink
      href={{ pathname, params }}
      locale={locale}
      variant="transparent"
    >
      {children}
    </ButtonLink>
  );
};

const LanguageList = () => {
  const currentLocale = useLocale();

  return (
    <ul className={css.languages}>
      {Object.entries(getAvailableLocalesToFullName(true, currentLocale)).map(
        ([locale, name]) => (
          <li key={locale}>
            <LanguageLink locale={locale as Locale}>{name}</LanguageLink>
          </li>
        ),
      )}
    </ul>
  );
};

export default LanguageList;
