import clsx from "clsx";

import { ReactNode } from "react";

import css from "./menu.module.css";

interface BorderSectionProps {
  children: ReactNode;
  contentDirection?: "row" | "column";
  containerClassName?: string;
  sectionClassName?: string;
}

const BorderSection = ({
  sectionClassName,
  containerClassName,
  contentDirection,
  children,
}: BorderSectionProps) => (
  <div className={clsx(css.borderSectionContainer, containerClassName)}>
    <section
      className={clsx(
        css.borderSection,
        sectionClassName,
        contentDirection === "column" && css.column,
      )}
    >
      {children}
    </section>
    <hr className={css.horizontalRule} />
  </div>
);

export default BorderSection;
