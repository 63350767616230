import { InMemoryCache } from "@apollo/experimental-nextjs-app-support";

export const getNewInMemoryCache = () =>
  new InMemoryCache({
    typePolicies: {
      OversatteTekster: {
        keyFields: ["nob"],
      },
    },
  });
