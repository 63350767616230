"use client";

import { useTranslations } from "next-intl";

import { useFakeUserContext } from "../../_lib/test-users/fake-user-context";
import css from "./test-user-banner.module.css";

export const TestUserBannerContent = () => {
  const t = useTranslations("fake-user-banner");
  const { fakeUserInfo } = useFakeUserContext();

  if (fakeUserInfo === undefined) {
    return null;
  }

  return (
    <div className={css.banner}>
      {t("logged-in-as", {
        firstName: fakeUserInfo.fornavn,
        lastName: fakeUserInfo.etternavn,
      })}
    </div>
  );
};
