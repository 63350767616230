import { Children, ReactNode, isValidElement } from "react";

export const extractChildrenTextContent = (node: ReactNode): string => {
  if (typeof node === "string") return node;

  if (isValidElement<{ children: ReactNode }>(node)) {
    return (
      Children.map(node.props.children, extractChildrenTextContent)?.join("") ??
      ""
    );
  }

  if (Array.isArray(node)) {
    return node.map(extractChildrenTextContent).join("");
  }

  return "";
};
