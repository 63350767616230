const AUTH_SESSION_TOKEN_COOKIE_NAME = "authjs.session-token";
const SECURE_PREFIX = "__Secure-";

type AuthCookieName =
  | `${typeof SECURE_PREFIX}${typeof AUTH_SESSION_TOKEN_COOKIE_NAME}`
  | typeof AUTH_SESSION_TOKEN_COOKIE_NAME;

export const getSessionTokenCookieName = (): AuthCookieName =>
  process.env.NODE_ENV === "production"
    ? `${SECURE_PREFIX}${AUTH_SESSION_TOKEN_COOKIE_NAME}`
    : AUTH_SESSION_TOKEN_COOKIE_NAME;
