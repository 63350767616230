"use client";

import { ReactNode, createContext, useCallback, useMemo } from "react";

import { FakeUserInfo } from "@/src/app/[locale]/_queries/types";
import { setFakeUserCookie } from "@/src/app/_lib/test-users/set-fake-user-cookie";
import { useNonNullableContext } from "@/src/components/hooks";

interface FakeUserContextProps {
  fakeUserInfo: FakeUserInfo | undefined;
  setFakeUserInfo: (fakeUserInfo: FakeUserInfo | undefined) => void;
}

const FakeUserContext = createContext<FakeUserContextProps | undefined>(
  undefined,
);

export const useFakeUserContext = () =>
  useNonNullableContext(FakeUserContext, "useFakeUserContext");

interface FakeUserProviderProps {
  children: ReactNode;
  initFakeUserInfo?: FakeUserInfo;
}

const FakeUserProvider = ({
  children,
  initFakeUserInfo,
}: FakeUserProviderProps) => {
  const setFakeUserInfo = useCallback(
    (fakeUserInfo: FakeUserInfo | undefined) => {
      setFakeUserCookie(fakeUserInfo)
        .then(() => {
          // Could also redirect to front page
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [],
  );

  const value: FakeUserContextProps = useMemo(
    () => ({
      fakeUserInfo: initFakeUserInfo,
      setFakeUserInfo,
    }),
    [initFakeUserInfo, setFakeUserInfo],
  );

  return (
    <FakeUserContext.Provider value={value}>
      {children}
    </FakeUserContext.Provider>
  );
};

export default FakeUserProvider;
