"use client";

import { createContext } from "react";

import { useNonNullableContext } from "@/src/components/hooks";
import { WithChildren } from "@/src/types/generic-react-props";

interface NextPublicEnvContextProps {
  appBaseUrl: string | undefined;
}

const NextPublicEnvContext = createContext<
  NextPublicEnvContextProps | undefined
>(undefined);

export const useNextPublicEnvContext = () =>
  useNonNullableContext(NextPublicEnvContext, "useNextPublicEnvContext");

/**
 * IMPORTANT: Do not expose any env variables that are not public here.
 * */
const NextPublicEnvProvider = ({
  children,
  ...props
}: NextPublicEnvContextProps & WithChildren) => (
  <NextPublicEnvContext.Provider value={props}>
    {children}
  </NextPublicEnvContext.Provider>
);

export default NextPublicEnvProvider;
