import { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { getSessionTokenCookieName } from "@/src/app/_lib/auth/get-session-token-cookie-name";
import { sudoHeadersByKey } from "@/src/app/api/graphql/types";

export const getAuthLink = (
  sessionTokenValue: string | undefined,
  fnr: string | undefined,
): ApolloLink | undefined =>
  sessionTokenValue
    ? setContext(() => ({
        headers: {
          Cookie: `${getSessionTokenCookieName()}=${sessionTokenValue}`,
          [sudoHeadersByKey.Fnr]: fnr,
          // TODO: Add dynamic orgnr, when support exists in API
          // [sudoHeadersByKey.Orgnr]: "971035854",
        },
      }))
    : undefined;
